// import React,{useEffect} from 'react';
// // import router from 'next/router';
import Head from '@/componentWebs/headLayout';
import cookie from 'js-cookie';
import getConfig from 'next/config';
import Header from '@/componentWebs/downloadvideo/Header';
import Footer from '@/componentWebs/downloadvideo/Footer';
import { languages } from '@/utils/utils';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { /* defineMessages, */ injectIntl } from 'react-intl';

const { publicRuntimeConfig } = getConfig();
const style = `${publicRuntimeConfig.DOMAIN_STATIC}/downloadvideo/css/styleDown.css`;

const MyComponent = ({ dataSite, intl, ...props }) => {
  const [site, setDataSite] = useState(dataSite || {});
  const [locale, setLocale] = useState('vi');
  const [languagesId, setLanguagesId] = useState('1');
  const [menuHeader, setMenuHeader] = useState([]);
  const [menuFooter, setMenuFooter] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { dispatch } = props;
      let host =
        window.location.host === 'localhost:8834'
          ? publicRuntimeConfig.SITE_NAME
          : window.location.host;

      if (host.indexOf('www') === 0) {
        host = host.slice(4);
      }
      let dataSiteNew = {};
      // Fetch site data
      await dispatch({
        type: 'webs/fetchSiteUrl',
        payload: { url: host },
        callback: result => {
          setDataSite(result);
          dataSiteNew = result;
        },
      });

      const SITEID_WEB = dataSiteNew?.id;
      const languagesDefault =
        dataSiteNew?.siteProfiles?.find(i => i?.isDefault === 1)?.languagesId || '1';
      const localeNew =
        cookie.get('locale') ||
        languages?.find(i => i?.id === languagesDefault)?.languagesCode ||
        'vi';
      const languagesIdNew =
        dataSiteNew?.siteProfiles?.find(i => i?.languages?.languagesCode === localeNew)
          ?.languagesId || '1';
      setLocale(localeNew);
      setLanguagesId(languagesIdNew);
      // Fetch menu data
      if (SITEID_WEB) {
        const queryMenus = {
          filter: {
            status: '1',
            menuPositionsId: `${publicRuntimeConfig.MENU__POSITIONID_TOP},${
              publicRuntimeConfig.MENU__POSITIONID_BOTTOM
            }`,
            languagesId: languagesIdNew,
            sitesId: SITEID_WEB,
          },
          sort: ['orderBy', 'ASC'],
        };
        dispatch({
          type: 'webs/fetchMenus',
          payload: queryMenus,
          callback: result => {
            const menus = result?.result?.list || [];
            const menuHeaderNew =
              (menus?.length > 0 && menus.filter(item => Number(item.menuPositionsId) === 4)) || [];
            const menuFooterNew =
              (menus?.length > 0 && menus.filter(item => Number(item.menuPositionsId) === 3)) || [];
            setMenuHeader(menuHeaderNew);
            setMenuFooter(menuFooterNew);
          },
        });
      }
    };

    if (!dataSite?.id) {
      fetchData();
    }
  }, []);

  return (
    <>
      <Head>
        <title>{intl.formatMessage({ id: 'title404' })}</title>
        <link rel="stylesheet" href={style} type="text/css" media="all" />
        <meta name="description" content={intl.formatMessage({ id: 'des404' })} />
        <meta property="og:description" content={intl.formatMessage({ id: 'des404' })} />{' '}
      </Head>
      {!dataSite?.id && (
        <Header
          {...props}
          menuHeader={menuHeader}
          dataSite={site}
          languagesId={languagesId}
          locale={locale}
        />
      )}
      <div className="wrapper">
        <section className="error-page">
          <div className="error-page-type">404</div>
          <h1>{intl.formatMessage({ id: 'title404' })}</h1>
          <h2>{intl.formatMessage({ id: 'des404' })}</h2>
          <h2>
            <span>{intl.formatMessage({ id: 'back' })}</span>
            <a href="/">{intl.formatMessage({ id: 'home' })}</a>
          </h2>
        </section>
      </div>
      {!dataSite?.id && (
        <Footer
          {...props}
          menuFooter={menuFooter}
          dataSite={site}
          languagesId={languagesId}
          locale={locale}
        />
      )}
    </>
  );
};

export default connect(({ webs }) => ({
  webs,
}))(injectIntl(MyComponent));
